import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import AboutPage from "@/views/AboutPage.vue";
import NewsPage from "@/views/NewsPage.vue";
import ShopPage from "@/views/ShopPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import FoodPage from "@/views/FoodPage.vue";
import CafePage from "@/views/CafePage.vue";
import FunPage from "@/views/FunPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/news',
    name: 'News',
    component: NewsPage
  },
  {
    path: '/food',
    name: 'Food',
    component: FoodPage
  },
  {
    path: '/cafe',
    name: 'Cafe',
    component: CafePage
  },
  {
    path: '/fun',
    name: 'Fun',
    component: FunPage
  },
  {
    path: '/shop',
    name: 'Shop',
    component: ShopPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/:catchAll(.*)*',
    name: 'Contact',
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
