
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  informationCircle,
  informationCircleOutline,
  listCircle,
  listCircleOutline,
  logoFacebook,
  logoGithub,
  logoTwitter,
  // logoGoogle,
  logoYoutube,
  cartOutline,
  cafeOutline,
  fastFoodOutline,
  rocketOutline, newspaperOutline
} from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Trang chủ',
        url: '/',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Giới thiệu',
        url: '/about',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircle
      },
      {
        title: 'Ẩm thực',
        url: '/food',
        iosIcon: fastFoodOutline,
        mdIcon: fastFoodOutline
      },
      {
        title: 'Café',
        url: '/cafe',
        iosIcon: cafeOutline,
        mdIcon: cafeOutline
      },
      {
        title: 'Vui chơi',
        url: '/fun',
        iosIcon: rocketOutline,
        mdIcon: rocketOutline
      },
      {
        title: 'Tin tức',
        url: '/news',
        iosIcon: newspaperOutline,
        mdIcon: newspaperOutline
      },
      {
        title: 'Mua Bán',
        url: '/shop',
        iosIcon: cartOutline,
        mdIcon: cartOutline
      },
      {
        title: 'Liên hệ',
        url: '/contact',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp
      }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    /* const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    } */

    const route = useRoute();
    return {
      selectedIndex,
      appPages,
      labels,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      logoFacebook,
      logoGithub,
      logoTwitter,
      // logoGoogle,
      logoYoutube,
      isSelected: (url: string) => url === route.fullPath ? 'selected' : ''
    }
  }
});
